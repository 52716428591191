import { AuthLayout, Btn, Form, FormInput, FormPasswordInput } from '@/components';
import { SignInFormData, VALIDATE_SIGNIN } from '@/features/auth/auth-util';
import { ADMIN_SIGNIN, SIGNIN_PARTNER } from '@/features/auth/mutations';
import { errorToast, successToast } from '@/utils/toast';
import { Heading, Stack, Text, useTheme, useToast } from '@chakra-ui/react';
import { HiLockClosed, HiMiniEnvelope } from 'react-icons/hi2';

import { isPartner } from '@/utils/chakra-theme';
import { save_token } from '@/utils/cookie-storage';
import { getErrorMessage } from '@/utils/functions';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';

const SignIn = () => {
  const { colors } = useTheme();
  const router = useRouter();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(VALIDATE_SIGNIN),
  });

  const [signInAgent, { loading }] = useMutation(SIGNIN_PARTNER);
  const [adminSignIn, { loading: loadingAdmin }] = useMutation(ADMIN_SIGNIN);

  const onSubmit = async (values: SignInFormData) => {
    try {
      if (isPartner) {
        await signInAgent({
          variables: values,
          onCompleted: (data) => {
            save_token(data?.partnerSignIn?.token);
            successToast('Success', 'Login successful');
            router.push('/account/dashboard');
          },
          onError: (error) => {
            errorToast('Error', getErrorMessage(error));
          },
        });
      } else {
        await adminSignIn({
          variables: values,
          onCompleted: (data) => {
            save_token(data?.adminSignIn?.token);
            successToast('Success', 'Login successful');
            router.push('/account/dashboard');
          },
          onError: (error) => {
            errorToast('Error', getErrorMessage(error));
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthLayout>
      <Stack w={['full', 360]} p={5} textAlign="center">
        <Heading fontWeight={600} fontSize={['36px']}>
          Welcome!
        </Heading>
        <Heading color="primary" mb={7} fontSize={['30px']}>
          Login
        </Heading>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            placeholder="Email"
            icon={<HiMiniEnvelope color={colors.icon} size={20} />}
            name="email"
            register={register}
            isInvalid={!!errors.email}
            validationMessage={errors?.email?.message}
            height="56px"
          />
          <FormPasswordInput
            placeholder="Password"
            icon={<HiLockClosed color={colors.icon} size={20} />}
            name="password"
            register={register}
            isInvalid={!!errors.password}
            validationMessage={errors?.password?.message}
            height="56px"
          />
          <Btn isLoading={loading} w="full" mb={[2]} type="submit">
            Submit
          </Btn>
        </Form>

        <Text color="primary" fontSize="md" textAlign="right" mb={8}>
          <Link href="/forgot-password">Forgot Password?</Link>
        </Text>

        {/* <Flex alignItems="center" justifyContent="center" mb={5} gap={2}>
          <Divider flex=".5" />
          <Text flex="1">Or Log in with</Text>
          <Divider flex=".5" />
        </Flex>

        <Flex w="full" justify="center" gap={4} mb={5}>
          <GoogleAuth />
          <AppleAuth />
        </Flex>

        <Text fontSize="md" textAlign="center">
          Don&apos;t have an account?{' '}
          <Box color="primary" as="span">
            <Link href="/register">Register</Link>
          </Box>
        </Text> */}
      </Stack>
    </AuthLayout>
  );
};

export default SignIn;
