import { gql } from 'graphql-tag';

export const SIGNUP_PARTNER = gql`
  mutation SignupPartner($code: String!, $phone: String!, $pinId: String!) {
    authenticateAgent(code: $code, phone: $phone, pinId: $pinId) {
      firstname
      id
      lastname
      phone
      token
    }
  }
`;

export const SIGNIN_PARTNER = gql`
  mutation SignInPartner($email: String!, $password: String!) {
    partnerSignIn(email: $email, password: $password) {
      message
      token
    }
  }
`;

export const ADMIN_SIGNIN = gql`
  mutation adminSignIn($email: String!, $password: String!) {
    adminSignIn(email: $email, password: $password) {
      message
      token
    }
  }
`;
