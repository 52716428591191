import * as yup from 'yup';

export const VALIDATE_SIGNUP = yup.object().shape({
  firstName: yup.string().trim().required('Enter your first name'),
  lastName: yup.string().trim().required('Enter your last name'),
  email: yup.string().trim().email('Enter a valid email address').required('Enter your valid email address'),
  phone: yup.string().trim().required('Enter a phone number').min(3, 'Enter a valid phone number'),
  region: yup.string().trim().required('Select a valid region'),
  password: yup.string().min(8, 'Enter a minimum of 8').required('Enter a password'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Passwords must match')
    .required('Enter your password again'),
});

export const VALIDATE_SIGNIN = yup.object().shape({
  email: yup.string().trim().email('Enter a valid email address').required('Enter your valid email address'),
  password: yup.string().min(8, 'Enter a minimum of 8').required('Enter a password'),
});

export const canadianRegions = [
  {
    value: 'atlantic_provinces',
    label: 'The Atlantic Provinces',
  },
  {
    value: 'central_canada',
    label: 'Central Canada',
  },
  {
    value: 'prairie_provinces',
    label: 'The Prairie Provinces',
  },
  {
    value: 'west_coast',
    label: 'The West Coast',
  },
  {
    value: 'northern_territories',
    label: 'The Northern Territories',
  },
];

export type SignUpFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  region: string;
  password: string;
  confirmPassword: string;
};

export type SignInFormData = {
  email: string;
  password: string;
};
